/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-subscriptions-reactive.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angularecommerce/core/components/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "@angularecommerce/core/components/dialog-header/dialog-header.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/flex-layout/flex";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "@angularecommerce/core/directives/button-locker/button-locker.directive";
import * as i14 from "./user-subscriptions-reactive.component";
import * as i15 from "@angularecommerce/core/services/http-response-messages/http-response-messages.service";
import * as i16 from "@angularecommerce/core/services/feedback/feedback.service";
import * as i17 from "@angularecommerce/core/services/user-subscriptions/user-subscriptions.service";
var styles_UserSubscriptionsReactiveComponent = [i0.styles];
var RenderType_UserSubscriptionsReactiveComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserSubscriptionsReactiveComponent, data: {} });
export { RenderType_UserSubscriptionsReactiveComponent as RenderType_UserSubscriptionsReactiveComponent };
export function View_UserSubscriptionsReactiveComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ae2-dialog-header", [], [[2, "ae2-dialog-header", null]], null, null, i2.View_Ae2DialogHeaderComponent_0, i2.RenderType_Ae2DialogHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.Ae2DialogHeaderComponent, [i4.MatDialogRef], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 11, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "form", [["class", "example-form"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i5.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(7, 540672, null, 0, i5.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormGroupDirective]), i1.ɵdid(9, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" A reativa\u00E7\u00E3o da assinatura permite voc\u00EA agendar aulas imediatamente e voc\u00EA s\u00F3 ser\u00E1 cobrado depois dos dias de cr\u00E9dito que possuir* serem consumidos. Deseja prosseguir? "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "text2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" * os dias de cr\u00E9dito s\u00E3o calculados pelo per\u00EDodo compreendido entre a data do congelamento da assinatura e a expira\u00E7\u00E3o inicial do seu pacote. "])), (_l()(), i1.ɵeld(14, 0, null, null, 11, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(15, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 9, "div", [["class", "action"], ["fxLayoutAlign", "space-between center"]], null, null, null, null, null)), i1.ɵdid(17, 737280, null, 0, i6.LayoutAlignDirective, [i7.MediaMonitor, i1.ElementRef, [8, null], i7.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 3, "button", [["mat-button", ""], ["mat-dialog-close", ""], ["type", "button"]], [[1, "aria-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).dialogRef.close(i1.ɵnov(_v, 19).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(19, 606208, null, 0, i4.MatDialogClose, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(20, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Voltar"])), (_l()(), i1.ɵeld(22, 0, null, null, 3, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(23, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), i1.ɵdid(24, 16384, null, 0, i13.Ae2ButtonLockerDirective, [i1.ElementRef, i1.Renderer2], { isActive: [0, "isActive"] }, null), (_l()(), i1.ɵted(-1, 0, ["Confirmar"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "Reativa\u00E7\u00E3o de Assinatura"; _ck(_v, 1, 0, currVal_1); var currVal_9 = _co.form; _ck(_v, 7, 0, currVal_9); var currVal_10 = "space-between center"; _ck(_v, 17, 0, currVal_10); var currVal_14 = ""; _ck(_v, 19, 0, currVal_14); var currVal_17 = _co.isLoading; var currVal_18 = "primary"; _ck(_v, 23, 0, currVal_17, currVal_18); var currVal_19 = _co.isLoading; _ck(_v, 24, 0, currVal_19); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 9).ngClassValid; var currVal_7 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_11 = i1.ɵnov(_v, 19).ariaLabel; var currVal_12 = (i1.ɵnov(_v, 20).disabled || null); var currVal_13 = (i1.ɵnov(_v, 20)._animationMode === "NoopAnimations"); _ck(_v, 18, 0, currVal_11, currVal_12, currVal_13); var currVal_15 = (i1.ɵnov(_v, 23).disabled || null); var currVal_16 = (i1.ɵnov(_v, 23)._animationMode === "NoopAnimations"); _ck(_v, 22, 0, currVal_15, currVal_16); }); }
export function View_UserSubscriptionsReactiveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-subscriptions-reactive", [], null, null, null, View_UserSubscriptionsReactiveComponent_0, RenderType_UserSubscriptionsReactiveComponent)), i1.ɵdid(1, 114688, null, 0, i14.UserSubscriptionsReactiveComponent, [i15.Ae2HttpResponseMessagesService, i4.MatDialogRef, i4.MAT_DIALOG_DATA, i16.Ae2FeedbackService, i17.Ae2UserSubscriptionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserSubscriptionsReactiveComponentNgFactory = i1.ɵccf("app-user-subscriptions-reactive", i14.UserSubscriptionsReactiveComponent, View_UserSubscriptionsReactiveComponent_Host_0, {}, {}, []);
export { UserSubscriptionsReactiveComponentNgFactory as UserSubscriptionsReactiveComponentNgFactory };
