import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ae2HttpResponseMessagesService } from '@angularecommerce/core/services/http-response-messages';
import { Ae2FeedbackService, Ae2FeedbackFormat } from '@angularecommerce/core/services/feedback';
import { Ae2UserSubscriptionsService } from '@angularecommerce/core/services/user-subscriptions/user-subscriptions.service';
import { Ae2UserUnFreezeSubscriptionsService } from '@angularecommerce/core/services/user-subscriptions/user-subscriptions.model';
import { Ae2DateTime } from '@angularecommerce/core/classes/datetime/datetime'

@Component({
  selector: 'app-user-subscriptions-reactive',
  templateUrl: './user-subscriptions-reactive.component.html',
  styleUrls: ['./user-subscriptions-reactive.component.scss'],
})
export class UserSubscriptionsReactiveComponent implements OnInit {

  form: FormGroup;

  minDate: Date = new Date();

  isLoading: boolean = false;

  constructor(
    private ae2HttpResponseMessagesService: Ae2HttpResponseMessagesService,
    private mdDialogRef: MatDialogRef<UserSubscriptionsReactiveComponent>,
    @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    private ae2FeedbackService: Ae2FeedbackService,
    private ae2UserSubscriptionsService: Ae2UserSubscriptionsService,
  ) {

  }

  ngOnInit(): void {
    this.form = this._creatForm();
  }

  _creatForm(): FormGroup {
    return new FormGroup({
      return_at: new FormControl(new Date(), Validators.required),
      cancel: new FormControl(false),
    });
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    const data: Ae2UserUnFreezeSubscriptionsService = new Ae2UserUnFreezeSubscriptionsService(
      new Ae2DateTime(new Date()).toISODate(),
      false,
    );
    this.ae2UserSubscriptionsService.unFreeze(this.matDialogData.number, data).subscribe(() => {
      this.mdDialogRef.close(true);
      this.isLoading = false;
      this.ae2FeedbackService.success({
        message: 'Assinatura alterada com sucesso',
        format: Ae2FeedbackFormat.Notification
      });
    }, res => {
      this.mdDialogRef.close(true);
      this.isLoading = false;
      this.ae2HttpResponseMessagesService.createFeedback(res);
    });

  }


}
