<aside class="aec">
    <div class="aec-i"></div>
    <div class="aec-t">
        Dúvidas ou comentários? Envie-nos um email ou ligue em um de nossos studios.
    </div>
    <div class="aec-l">
        <div class="aec-l-t">e-mail</div>
        <a href="mailto:velocity@studiovelocity.com.br">velocity@studiovelocity.com.br</a>
    </div>
    <div class="aec-l">
        <div class="aec-l-t">telefones</div>
        <button class="aec-l-l" routerLink="/unidades" (click)="close()" mat-button >veja lista dos studios</button>
    </div>
</aside>