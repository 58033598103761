import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSubscriptionCancelComponent } from './user-subscription-cancel.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LocalizationModule } from 'angular-l10n';
import { Ae2DialogHeaderModule } from '@angularecommerce/core/components/dialog-header';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    LocalizationModule,
    Ae2DialogHeaderModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule,
  ],
  declarations: [UserSubscriptionCancelComponent],
  entryComponents: [UserSubscriptionCancelComponent]
})
export class UserSubscriptionCancelModule { }
