import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UnitSelectiotButtomComponent, UnitSelectorComponent } from './unit-selection.component';
import { Ae2UnitSelectorServiceModule } from '@angularecommerce/core/services/unit-selector';
import { Ae2ActivityServiceModule } from '@angularecommerce/core/services/activities';
import { Ae2UnitSelectorModule } from '@angularecommerce/core/components/unit-selector';
import { MatIconModule, MatButtonModule } from '@angular/material';
import { Ae2ArticleModule } from '@angularecommerce/core/components/article';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    Ae2UnitSelectorModule,
    FlexLayoutModule,
    Ae2UnitSelectorServiceModule,
    Ae2ActivityServiceModule,
    MatIconModule,
    MatButtonModule,
    Ae2ArticleModule
  ],
  declarations: [
    UnitSelectiotButtomComponent,
    UnitSelectorComponent
  ],
  exports: [UnitSelectiotButtomComponent],
  entryComponents: [UnitSelectorComponent]
})
export class UnitSelectionModule { }
