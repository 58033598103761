import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { Ae2CoreModule } from '@angularecommerce/core/modules/core';
import { Ae2MyAccountGlobalConfig, MenuInterface, AE2_MY_ACCOUNT_CONFIG_TOKEN } from '@angularecommerce/core/components/my-account';
import { Ae2AuthStoreModule } from '@angularecommerce/core/stores/auth';
import { Ae2HeaderStoreModule } from '@angularecommerce/core/stores/header';
import { Ae2UnitSelectorStoreModule } from '@angularecommerce/core/stores/unit-selector';
import { Ae2AuthVerificationInitializerModule } from '@angularecommerce/core/modules/auth-verification-initializer';
import { Ae2UnitSelectorInitializerModule } from '@angularecommerce/core/modules/unit-selector-initializer';
import { HeaderModule } from './shared/components/header/header.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { Ae2BagStoreModule } from '@angularecommerce/core/stores/bag';
import { UserSubscriptionsReactiveModule
} from './features/profile/my-subscribes/user-subscriptions-reactive/user-subscriptions-reactive.module';
import { CovidModalModule } from './shared/components/covid-modal/covid-modal.module';
import { UserSubscriptionCancelModule } from './features/profile/my-subscribes/user-subscription-cancel/user-subscription-cancel.module';
import { OtherInstructorModule } from './shared/components/other-instructor/other-instructor.module';
import { ChangeGiftModule } from './shared/components/change-gift/change-gift.module';
import { RetryPaymentModule } from './shared/components/retry-payment/retry-payment.module';
export class MyAccountCustomConfig extends Ae2MyAccountGlobalConfig {

  titleEvents: string = 'Minhas Reservas';

  showFTP: boolean = false;

  menu: MenuInterface[] = [
    { name: 'minhas Reservas', link: 'meus-eventos', icon: 'event' },
    { name: 'meus créditos', link: 'meus-creditos', icon: 'account_balance' },
    { name: 'meus pedidos', link: 'meus-pedidos', icon: 'bookmark_border' },
    { name: 'minhas assinaturas', link: 'minhas-assinaturas', icon: 'bookmark' },
    { name: 'cartões de crédito', link: 'metodos-pagamento', icon: 'credit_card' },
    { name: 'meus dados', link: 'meus-dados', icon: 'account_circle' },
    { name: 'alterar senha', link: 'alterar-senha', icon: 'lock' },
    { name: 'meus endereços', link: 'meus-enderecos', icon: 'room' },
    { name: 'dados de performance', link: 'dados-de-performance', icon: 'timeline' }
  ];
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,

    HeaderModule,
    FooterModule,
    
    AppRoutingModule,

    Ae2CoreModule,

    Ae2AuthVerificationInitializerModule,
    Ae2UnitSelectorInitializerModule,

    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),

    /**
     * Remova esse módulo se não estiver usando componente de sacola
     */
    Ae2BagStoreModule,

    /**
     * Remova esse módulo se não estiver usando sistema de autenticação
     */
    Ae2AuthStoreModule,

    /**
     * Remova esses módulos se não estiver usando header-widget no site
     */
    Ae2HeaderStoreModule,
    Ae2UnitSelectorStoreModule,
    UserSubscriptionsReactiveModule,
    CovidModalModule,
    ChangeGiftModule,
    RetryPaymentModule,
    UserSubscriptionCancelModule,
    OtherInstructorModule
  ],
  providers: [
    { provide: AE2_MY_ACCOUNT_CONFIG_TOKEN, useClass: MyAccountCustomConfig }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
