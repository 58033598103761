import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Ae2MarketingService, Ae2MarketingEmailsAcquired } from '@angularecommerce/core/services/marketing';
import { ae2EmailValidatorFn } from '@angularecommerce/core/functions/email-validator';
import { Ae2FeedbackService, Ae2FeedbackFormat } from '@angularecommerce/core/services/feedback';
import { MatDialog } from '@angular/material/dialog';
import { ContactComponent } from '../contact/contact.component';
import { TestersComponent } from '../testers/testers.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {
  data: number;
  form: FormGroup;
  constructor(
    private marketingService: Ae2MarketingService,
    private feedbackService: Ae2FeedbackService,
    private mdDialog: MatDialog) {
  }
  ngOnInit(): void {
    this.data = new Date().getFullYear();
    this.form = this.createForm();
  }
  submit(): void {
    if (this.form.get('email').value === '') {
      return;
    }
    const data = new Ae2MarketingEmailsAcquired();
    data.email = this.form.get('email').value;
    data.source = 'https://ecommerce2-dev.angular-ecommerce.com/';
    data.campaign = 'newslleter';
    this.marketingService.subscribeUser(data).subscribe(res => {
      if (res.email) {
        this.form.reset();
        this.feedbackService.success({
          message: 'Newsletter assinada com sucesso',
          format: Ae2FeedbackFormat.Notification,
          duration: 2000
        });
      }
    });
  }
  targetLint(link: string): void {
    window.open(link, '_blank');
  }
  protected createForm(): FormGroup {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.minLength(6), ae2EmailValidatorFn])
    });
  }
  goToTop(): void {
    // document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0;
    const scrollHeight = window.scrollY,
      scrollStep = Math.PI / (600 / 15),
      cosParameter = scrollHeight / 2;
    let scrollCount = 0, scrollMargin;
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        scrollCount = scrollCount + 1;
        scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
        window.scrollTo(0, (scrollHeight - scrollMargin));
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  }
  openContact(): void {
    const dialogRef = this.mdDialog.open(ContactComponent, {
      disableClose: false,
      width: '520px',
      data: null
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openModalGooglePlay(): void {
    const dialogRef = this.mdDialog.open(TestersComponent, {
      disableClose: false,
      width: '520px',
      data: null
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
