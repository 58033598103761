import { Component, OnInit, Inject } from '@angular/core';
import { Ae2UserModel } from '@angularecommerce/core/services/users';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Ae2DialogResponse } from '@angularecommerce/core/classes/dialog-response';
import { Ae2HttpResponseMessagesService } from '@angularecommerce/core/services/http-response-messages';
import { Ae2FeedbackFormat, Ae2FeedbackService } from '@angularecommerce/core/services/feedback';
import { Ae2DateTime } from '@angularecommerce/core/classes/datetime/datetime';
import { Ae2UserService } from '@angularecommerce/core/services/users/users.service';
import { Store, select } from '@ngrx/store';
import { Ae2AuthSetSessionAction, Ae2AuthUpdateEffectAction } from '@angularecommerce/core/stores/auth/auth-actions';
import { Ae2AlertDialogData } from '@angularecommerce/core/services/dialog/dialog.model';
import { Ae2DialogService } from '@angularecommerce/core/services/dialog/dialog.service';
import { Ae2SaleOrderModel } from '@angularecommerce/core/services/sales-order/sales-order.model';
import { Ae2SalesOrderService } from '@angularecommerce/core/services/sales-order';
import { Ae2CreditCardsServiceGetAllQuery } from '@angularecommerce/core/services/credit-cards/credit-cards.service';
import {
    Ae2CreditCardModel, Ae2CreditCardsService,
    Ae2CreditCardPayloadModel
} from '@angularecommerce/core/services/credit-cards';
import { UserCardsFormComponent } from 'app/features/profile/my-cards/user-cards-form/user-cards-form.component';
import { MatDialog } from '@angular/material';
@Component({
    selector: 'app-retry-payment',
    templateUrl: './retry-payment.component.html',
    styleUrls: ['./retry-payment.component.sass'],
    providers: [Ae2UserService]
})
export class RetryPaymentComponent implements OnInit {

    form: FormGroup;

    isLoading: boolean = false;

    order: Ae2SaleOrderModel;
    user: Ae2UserModel;
    cards: Ae2CreditCardModel[] = [];

    error: boolean = false;

    constructor(
        private mdDialogRef: MatDialogRef<RetryPaymentComponent>,
        @Inject(MAT_DIALOG_DATA) private mdDialogData: any,
        private ae2HttpResponseMessagesService: Ae2HttpResponseMessagesService,
        private ae2FeedbackService: Ae2FeedbackService,
        private ae2UserService: Ae2UserService,
        private store: Store<any>,
        private ae2DialogService: Ae2DialogService,
        private ae2SalesOrderService: Ae2SalesOrderService,
        private ae2CreditCardsService: Ae2CreditCardsService,
        private dialog: MatDialog
    ) {

    }

    ngOnInit(): void {
        this.order = this.mdDialogData.order;
        this.user = this.mdDialogData.user;
        // this.form = this.createForm();
        this.getCards();
    }

    getCards(): void {
        const query = new Ae2CreditCardsServiceGetAllQuery();
        query.user_id = this.user.id;

        this.ae2CreditCardsService.getAll(query)
            .getAllPages$({ waitAll: true })
            .subscribe(res => {
                this.cards = res.results;
            });
    }

    // private createForm(): FormGroup {
    //     return new FormGroup({
    //         recipientEmail: new FormControl(this.gift.recipientEmail, Validators.required),
    //         sendAfter: new FormControl(new Ae2DateTime(this.gift.sendAfter).format('DD/MM/YYYY')),
    //         recipientMessage: new FormControl(this.gift.recipientMessage),
    //     });
    // }

    close(): void {
        this.mdDialogRef.close(new Ae2DialogResponse(false));
    }

    changePayment(event: any, card: any = null): void {
        this.isLoading = true;
        let timer = 1;
        this.ae2SalesOrderService.retryAuthorization(this.order.number, null, null, null, card ? card : event.target.value).subscribe(res => {
            const clearTimer = setInterval(() => {
                if (timer >= 12) {
                    clearInterval(clearTimer);
                    this.isLoading = false;
                    this.error = true;
                }
                this.ae2SalesOrderService.get(this.order.number).subscribe(o => {
                    timer = timer + 1;
                    if (o.status !== this.order.status) {
                        this.isLoading = false;
                        clearInterval(clearTimer);
                        this.mdDialogRef.close(new Ae2DialogResponse(true));
                        this.error = true;
                    }
                })
            }, 5000);
        }, error => {
            this.ae2HttpResponseMessagesService.createFeedback(error);
            this.isLoading = false;
            this.mdDialogRef.close(new Ae2DialogResponse(false));
        });
    }

    openDialofCard(): void {
        const dialogRef = this.dialog.open(UserCardsFormComponent, {
            width: '400px',
            data: {
                user: this.user,
                isRetry: true,
            }
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data && data.success) {
                this.changePayment(null, data.card.id);
            }
        });
    }

}
