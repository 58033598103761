import {
  Component, OnInit, OnDestroy, ChangeDetectorRef, ComponentRef,
  Input, ElementRef, Injectable
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs/Subject';
import { Overlay, ScrollStrategy, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Ae2LocationModel } from '@angularecommerce/core/services/location';
import { Ae2UnitModel } from '@angularecommerce/core/services/units';
import { fromEvent } from 'rxjs';
import { Ae2UnitSelectorState, ae2UnitSelectorStoreSelector } from '@angularecommerce/core/stores/unit-selector';
import { takeUntil, filter, take, tap } from 'rxjs/operators';
import { Ae2ActivityModel, Ae2ActivitiesService, Ae2ActivitiesServiceGetAllQuery } from '@angularecommerce/core/services/activities';
import { Ae2UnitSelectorService } from '@angularecommerce/core/services/unit-selector';
import { Ae2UnitSelectorSetEffectAction } from '@angularecommerce/core/stores/unit-selector/unit-selector-actions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser/';
import { Ae2DialogService } from '@angularecommerce/core/services/dialog/dialog.service';
import { Ae2ConfirmDialogData } from '@angularecommerce/core/services/dialog/dialog.model';
import { Ae2UnitsService, Ae2UnitsServiceGetAllQuery } from '@angularecommerce/core/services/units/units.service';

export class UnitSelectorGroup {
  location: Ae2LocationModel;
  units: Ae2UnitModel[];

  constructor(conf: UnitSelectorGroup) {
    Object.assign(this, conf);
  }
}



class CustomCloseScrollStrategy implements ScrollStrategy {

  enable(): void { }
  disable(): void { }

  attach(overlayRef: OverlayRef): void {
    fromEvent(window, 'scroll')
      .pipe(take(1))
      .subscribe(() => {
        overlayRef.detach();
        overlayRef.dispose();
      });
  }
}

@Injectable()
@Component({
  selector: 'app-unit-selection-component',
  templateUrl: './unit-selection-buttom.component.html',
  styleUrls: ['./unit-selection.component.sass']
})
export class UnitSelectiotButtomComponent implements OnInit, OnDestroy {

  selectedUnit: Ae2UnitModel;

  private unitSelectorStore$: Observable<Ae2UnitSelectorState>;

  private destroy$: Subject<any> = new Subject();

  @Input() labelInButtom: string;

  @Input() openInComponent: boolean = true;

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    protected store: Store<any>) {
    this.unitSelectorStore$ = store.pipe(select(ae2UnitSelectorStoreSelector));
  }

  ngOnInit(): void {
    this.unitSelectorStore$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => Boolean(state))
      )
      .subscribe(state => {
        this.selectedUnit = state.unit;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  openPopOver(activity: number = null): void {
    const positionStrategy = this.overlay.position()
      .connectedTo(this.elementRef,
        {
          originX: 'end', originY: 'bottom'
        },
        {
          overlayX: 'end', overlayY: 'top'
        });
    const overlayRef = this.overlay.create({
      positionStrategy: positionStrategy,
      // Aparentemente na beta.10 não funciona a scrollStrategies.close
      // verificar após upgrade
      scrollStrategy: new CustomCloseScrollStrategy(),
      width: this.elementRef.nativeElement.offsetWidth,
      panelClass: 'unit-selector-pane',
      hasBackdrop: true
    });
    overlayRef.backdropClick().subscribe(() => {
      overlayRef.detach();
      overlayRef.dispose();
    });
    // tslint:disable-next-line: no-use-before-declare
    const userProfilePortal = new ComponentPortal(UnitSelectorComponent);
    const componentRef: ComponentRef<UnitSelectorComponent> = overlayRef.attach(userProfilePortal);
    componentRef.instance.setData(overlayRef);
    if (activity) {
      componentRef.instance.setActivityStore(activity);
    }
  }



}


@Component({
  selector: 'app-unit-selector',
  templateUrl: './unit-selection.component.html',
  styleUrls: ['./unit-selection.component.sass']
})
export class UnitSelectorComponent implements OnInit, OnDestroy {

  selectedUnit: Ae2UnitModel;

  activitySeleted: number;

  activitySeletedArticle: Ae2ActivityModel;

  activities: Ae2ActivityModel[] = [];

  unitGroup: UnitSelectorGroup[];

  unitGroupSeleted: UnitSelectorGroup;

  unitList: Ae2UnitModel[] = [];

  changeLocal: boolean = false;

  isChangeLocation: boolean = false;

  isFullBody: boolean = false;

  isArticle: boolean = false;

  private overlayRef: OverlayRef;

  private unitSelectorStore$: Observable<Ae2UnitSelectorState>;

  private destroy$: Subject<any> = new Subject();

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private ae2UnitSelectorService: Ae2UnitSelectorService,
    private ae2ActivitiesService: Ae2ActivitiesService,
    private store: Store<any>,
    private ae2UnitsService: Ae2UnitsService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: Ae2DialogService
  ) {
    this.unitSelectorStore$ = store.pipe(select(ae2UnitSelectorStoreSelector));
  }

  ngOnInit(): void {
    this.unitSelectorStore$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => Boolean(state))
      )
      .subscribe(state => {
        this.selectedUnit = state.unit;

        this.creatGroup()
          .subscribe(() => {
            setTimeout(() => {
              this.getAllActivities();
            }, 100);
          });

        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  transformText(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  setData(overlayRef: OverlayRef): void {
    this.overlayRef = overlayRef;
  }

  setActivityStore(activity: number): void {
    this.activitySeleted = activity;
    this.isFullBody = true;
  }

  creatGroup(): Observable<Ae2UnitModel[]> {
    const query = new Ae2UnitsServiceGetAllQuery();
    query.enabled = true;
    query.use_as_filter = true;
    query.activity_list = `${this.activitySeletedArticle ? this.activitySeletedArticle.id : 1}`;

    const paginator = this.ae2UnitsService.getAll(query);

    return new Observable<Ae2UnitModel[]>(observer => {
      const allUnits = [];

      paginator.getAllPages$()
        .subscribe(units => {

          allUnits.push(...units.results);

          if (!paginator.hasNextPage()) {
            observer.next(allUnits);
            this.unitGroup = this.ae2UnitSelectorService.createUnitSelectorGroup(allUnits);
            this.unitGroup = this.unitGroup.sort((a, b) => {
              if (a.location.name < b.location.name) { return -1; }
              if (a.location.name > b.location.name) { return 1; }
              return 0;
            });
            this.unitGroupSeleted = this.unitGroup.filter(fill => fill.location.id === this.selectedUnit.location)[0];
            this.changeDetectorRef.markForCheck();
          }

        });
    });
    // return new Observable(this.ae2UnitsService.getAll().getAllPages$());

    // return this.ae2UnitSelectorService.getUnits$()
    //   .pipe(tap(units => {
    //     this.unitGroup = this.ae2UnitSelectorService.createUnitSelectorGroup(units);
    //     this.unitGroup = this.unitGroup.sort((a, b) => {
    //       if (a.location.name < b.location.name) { return -1; }
    //       if (a.location.name > b.location.name) { return 1; }
    //       return 0;
    //     });
    //     this.createUnit();
    //     this.changeDetectorRef.markForCheck();
    //   }));
  }

  createUnitLst(): void {
    if (this.activitySeleted && this.unitGroupSeleted) {
      const units = this.unitGroupSeleted.units.filter(fill => fill.activities.filter(act => act === this.activitySeleted).length !== 0);
      this.unitList = units.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
    }
  }

  setActivity(activity: Ae2ActivityModel): void {
    if (activity) {
      this.activitySeletedArticle = null;
      setTimeout(() => {
        this.activitySeletedArticle = activity;
      }, 100);
      this.activitySeleted = activity.id;
      this.isArticle = false;
      if (activity.slug === 'kore') {
        this.isFullBody = true;
      } else {
        this.isFullBody = false;
      }
      this.createUnitLst();
    }
  }

  storeChange(unit: Ae2UnitModel): void {
    this.store.dispatch(new Ae2UnitSelectorSetEffectAction({
      unit
    }));
    this.overlayRef.detach();
    this.overlayRef.dispose();
    // this.router.navigateByUrl('/agenda');
    this.router.navigate(['agenda'], { queryParams: { activities: this.activitySeleted } });

  }

  getAllActivities(): void {
    const query: Ae2ActivitiesServiceGetAllQuery = new Ae2ActivitiesServiceGetAllQuery();
    query.active = true;
    query.use_as_filter = true;
    this.ae2ActivitiesService.getAll(query)
      .getData$()
      .subscribe(res => {
        this.activities = res.results.filter(fill => fill.tags.indexOf('bike') >= 0);
        this.activitySeleted = this.activitySeleted ? this.activitySeleted : this.activities[0].id;
        this.activitySeletedArticle = this.activitySeletedArticle ? this.activitySeletedArticle : this.activities[0];
        this.isArticle = false;
        this.createUnitLst();
      });
  }

  changeLocation(): void {
    this.changeLocal = true;
  }

  closeLocation(): void {
    this.changeLocal = false;
  }

  changeLocationValue(unit: UnitSelectorGroup): void {
    this.unitGroupSeleted = unit;
    this.selectedUnit = unit.units.filter(fill => fill.location = unit.location.id)[0];
    this.isChangeLocation = true;
    this.createUnitLst();
    this.closeLocation();
  }

  buyPackeages(): void {
    this.overlayRef.detach();
    this.overlayRef.dispose();
    this.router.navigateByUrl('/pacotes');
  }

  viewData(event: any): void {
    if (event) {
      this.isArticle = true;
    }
  }

  openModalKore(unit: Ae2UnitModel): void {
    const data: Ae2ConfirmDialogData = new Ae2ConfirmDialogData();
    // tslint:disable-next-line: max-line-length
    data.message = 'A partir de agora as reservas e compras do Kore deverão ser realizadas em studiokore.com.br. Clique no botão abaixo para ser redirecionado para lá.';
    data.noButtonLabel = 'Cancelar';
    data.yesButtonLabel = 'Leve-me ao Kore';
    this.dialog.openConfirmDialog(data).subscribe((res) => {
      if (res) {
        window.location.assign(`https://studiokore.com.br/agenda?units=${unit.id}`);
      }
    })
  }



}

