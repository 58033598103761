import { Routes } from '@angular/router';
import { Ae2AuthRouteGuardService } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
import { HomeComponent } from './features/home/home.component';
var ɵ0 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ1 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ2 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ3 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ4 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ5 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ6 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ7 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ8 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ9 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ10 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ11 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ12 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ13 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ14 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ15 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ16 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ17 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ18 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ19 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ20 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ21 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ22 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ23 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ24 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ25 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ26 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ27 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ28 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ29 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ30 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ31 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ32 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ33 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ34 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ35 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ36 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ37 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ38 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ39 = { someProperty: AE2_ROUTER_CLASSES_DATA };
var routes = [
    {
        path: '',
        component: HomeComponent
    },
    // {
    //   path: 'login',
    //   loadChildren: 'app/features/login/login.module#LoginModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    {
        path: 'online/class',
        loadChildren: 'app/features/online-class/online-class.module#OnlineClassModule'
    },
    {
        path: 'online/thank-you',
        loadChildren: 'app/features/online-class-thanks/online-class-thanks.module#OnlineClassThanksModule'
    },
    {
        path: 'online/already-connected',
        loadChildren: 'app/features/online-class-disconnected/online-class-disconnected.module#OnlineClassDisconnectedModule'
    },
    {
        path: 'categoria/:categoryId',
        loadChildren: 'app/features/products/products.module#ProductsModule',
        data: ɵ0
    },
    {
        path: 'categoria/:categoryId/subcategoria/:subcategoryId',
        loadChildren: 'app/features/products/products.module#ProductsModule',
        data: ɵ1
    },
    {
        path: 'categoria/:categoryId/subcategoria/:subcategoryId/subsubcategoria/:subsubcategoryId',
        loadChildren: 'app/features/products/products.module#ProductsModule',
        data: ɵ2
    },
    {
        path: 'minha-conta',
        canActivate: [Ae2AuthRouteGuardService],
        loadChildren: 'app/features/profile/profile.module#ProfileModule',
        data: ɵ3
    },
    {
        path: 'checkout',
        // canActivate: [Ae2AuthRouteGuardService],
        loadChildren: 'app/features/checkout/checkout.module#CheckoutModule',
        data: ɵ4
    },
    {
        path: 'agenda',
        loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
        data: ɵ5
    },
    {
        path: 'evento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ6
    },
    {
        path: 'atividades',
        loadChildren: 'app/features/list-activities/list-activities.module#ListActivitiesModule',
        data: ɵ7
    },
    {
        path: 'atividades/:slug/:id',
        loadChildren: 'app/features/activity/activity.module#ActivityModule',
        data: ɵ8
    },
    {
        path: 'atividades/tickets/:slug/:id',
        loadChildren: 'app/features/activity-ticket/activity-ticket.module#ActivityTicketModule',
        data: ɵ9
    },
    {
        path: 'atividades/:slugatividade/:slugevento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ10
    },
    {
        path: 'instrutor/:slug/:id',
        loadChildren: 'app/features/instructor/instructor.module#InstructorModule',
        data: ɵ11
    },
    {
        path: 'instrutores',
        loadChildren: 'app/features/list-instructors/list-instructors.module#ListInstructorsModule',
        data: ɵ12
    },
    {
        path: 'instrutores/:unit/:id',
        loadChildren: 'app/features/list-instructors/list-instructors.module#ListInstructorsModule',
        data: ɵ13
    },
    {
        path: 'clientes/resetar-senha/:uidb/:token',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ14
    },
    {
        path: 'clientes/resetar-senha',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ15
    },
    {
        path: 'faq',
        loadChildren: 'app/features/faq/faq.module#FaqModule',
        data: ɵ16
    },
    {
        path: 'termos-e-condicoes',
        loadChildren: 'app/features/termos/termos.module#TermosModule',
        data: ɵ17
    },
    {
        path: 'politica-de-privacidade',
        loadChildren: 'app/features/policy/policy.module#PolicyModule',
        data: ɵ18
    },
    {
        path: 'regimento-interno',
        loadChildren: 'app/features/bylaws/bylaws.module#BylawsModule',
        data: ɵ19
    },
    {
        path: 'regras/:code',
        loadChildren: 'app/features/rules/rules.module#RulesModule',
        data: ɵ20
    },
    // {
    //   path: 'depoimentos',
    //   loadChildren: 'app/features/testimonials/testimonials.module#TestimonialsModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    // {
    //   path: 'lp/:slug',
    //   loadChildren: 'app/features/flat-pages/flat-pages.module#FlatPagesModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    //   {
    //     path: 'pacotes',
    //     loadChildren: 'app/features/packages/packages.module#PackagesModule',
    //     data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    //   },
    {
        path: 'pacotes',
        loadChildren: 'app/features/packages-new/packages.module#PackagesModule',
        data: ɵ21
    },
    {
        path: 'pacotes/:city/:unit',
        loadChildren: 'app/features/packages-new/packages.module#PackagesModule',
        data: ɵ22
    },
    {
        path: 'sobre',
        loadChildren: 'app/features/about/about.module#AboutModule',
        data: ɵ23
    },
    {
        path: 'unidades',
        loadChildren: 'app/features/studios/studios.module#StudiosModule',
        data: ɵ24
    },
    // {
    //   path: 'fullbody',
    //   loadChildren: 'app/features/fullbody/fullbody.module#FullBodyModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    {
        path: 'kore',
        loadChildren: 'app/features/kore/kore.module#KoreModule',
        data: ɵ25
    },
    {
        path: 'unidades/:slug',
        loadChildren: 'app/features/studios/studios.module#StudiosModule',
        data: ɵ26
    },
    {
        path: 'unidades/:slug/:id',
        loadChildren: 'app/features/unit/unit.module#UnitModule',
        data: ɵ27
    },
    {
        path: 'unidades/:slug/:city/:id',
        loadChildren: 'app/features/unit/unit.module#UnitModule',
        data: ɵ28
    },
    {
        path: 'aulas-tematicas',
        loadChildren: 'app/features/thematic/thematic.module#ThematicModule',
        data: ɵ29
    },
    {
        path: 'aulas-tematicas/:slug/:id',
        loadChildren: 'app/features/thematic/thematic.module#ThematicModule',
        data: ɵ30
    },
    {
        path: 'aulas-tematicas/:slug',
        loadChildren: 'app/features/thematic/thematic.module#ThematicModule',
        data: ɵ31
    },
    {
        path: 'revalidate',
        loadChildren: 'app/features/re-register/re-register.module#ReRegisterModule',
        data: ɵ32
    },
    {
        path: 'recadastramento',
        loadChildren: 'app/features/re-register/re-register.module#ReRegisterModule',
        data: ɵ33
    },
    {
        path: 'carreiras',
        loadChildren: 'app/features/careers/careers.module#CareersModule',
        data: ɵ34
    },
    {
        path: 'carreiras/:jobs',
        loadChildren: 'app/features/career/career.module#CareerModule',
        data: ɵ35
    },
    {
        path: 'velocity-eventos',
        loadChildren: 'app/features/events/events.module#EventsModule',
        data: ɵ36
    },
    {
        path: 'velocity-eventos/:slug',
        loadChildren: 'app/features/events-page/events-page.module#EventsPageModule',
        data: ɵ37
    },
    {
        path: 'artigo/:code',
        loadChildren: 'app/features/article/article.module#ArticleModule',
        data: ɵ38
    },
    {
        path: 'remover-conta',
        loadChildren: 'app/features/removal/removal.module#RemovalModule',
        data: ɵ39
    },
    { path: '**', pathMatch: 'full', redirectTo: '' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39 };
