<ae2-dialog-header [title]="'Alterar dados'"></ae2-dialog-header>

<mat-dialog-content fxLayout="column" fxLayoutGap="10px">
    <ng-container *ngIf="gift">
        <form [formGroup]="form" (ngSubmit)="update($event)">
            <div fxLayout="column">
                <div fxLayout fxLayout.xs="column" fxLayoutGap="10px">
                    <mat-form-field fxFlex="50%" fxFlex.xs="auto">
                        <input matInput placeholder="Destinatário" formControlName="recipientEmail">
                    </mat-form-field>
                    <mat-form-field fxFlex="50%" fxFlex.xs="auto" [matTooltip]="'Se você preencher este campo, nós enviaremos o e-mail com o Gift Card apenas neste dia e horário.'">
                        <input matInput placeholder="Data de entrega (?)" formControlName="sendAfter" ae2Mask="99/99/9999 99:99" [specialCharacters]="true">
                    </mat-form-field>
                </div>
                <mat-form-field fxFlex="100%" fxFlex.xs="auto">
                    <textarea matInput placeholder="Mensagem" formControlName="recipientMessage"></textarea>
                </mat-form-field>
    
    
            </div>
        </form>
    </ng-container>

</mat-dialog-content>

<mat-dialog-actions fxLayout="column" fxLayoutGap="10px">
    <div fxLayoutAlign="center center" class="action">
        <button mat-raised-button color="primary" [ae2ButtonLocker]="isLoading" (click)="submit()">Reenviar ao destinatário</button>
    </div>
</mat-dialog-actions>