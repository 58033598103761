import { NgModule } from '@angular/core';
import { ContactComponent } from './contact.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MatButtonModule
  ],
  declarations: [ContactComponent],
  exports: [ContactComponent],
  entryComponents: [ContactComponent]
})
export class ContactModule { }
