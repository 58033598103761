import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ae2HttpResponseMessagesService } from '@angularecommerce/core/services/http-response-messages';
import { Ae2FeedbackService, Ae2FeedbackFormat } from '@angularecommerce/core/services/feedback';
import { Ae2UserSubscriptionsService } from '@angularecommerce/core/services/user-subscriptions/user-subscriptions.service';

@Component({
  selector: 'app-user-subscription-cancel',
  templateUrl: './user-subscription-cancel.component.html',
  styleUrls: ['./user-subscription-cancel.component.scss'],
})
export class UserSubscriptionCancelComponent implements OnInit {

  form: FormGroup;

  step: number = 1;

  reason: string = '';

  constructor(
    private ae2HttpResponseMessagesService: Ae2HttpResponseMessagesService,
    private mdDialogRef: MatDialogRef<UserSubscriptionCancelComponent>,
    @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    private ae2FeedbackService: Ae2FeedbackService,
    private ae2UserSubscriptionsService: Ae2UserSubscriptionsService,
  ) {

  }

  ngOnInit(): void {
    this.form = this._creatForm();
  }

  _creatForm(): FormGroup {
    return new FormGroup({
      cancel_reason: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });
  }

  submit(): void {
    if (this.step === 1) {
      this.step = 2;
      return;
    }
    if (this.step === 2) {
      this.send();
    }
  }

  send(): void {
    if (this.form.invalid) {
      return;
    }
    this.ae2UserSubscriptionsService.delete(this.matDialogData.number, this.form.get('cancel_reason').value)
      .subscribe(res => {
        this.step = 3;
      }, error => {
        const response = JSON.parse(error._body);
        this.step = 4;
        this.reason = response.non_field_errors;
        // this.mdDialogRef.close(true);
        // this.ae2HttpResponseMessagesService.createFeedback(res);
      });
    // const data: Ae2UserUnFreezeSubscriptionsService = new Ae2UserUnFreezeSubscriptionsService(
    //   new Ae2DateTime(new Date()).toISODate(),
    //   false,
    // );
    // this.ae2UserSubscriptionsService.unFreeze(this.matDialogData.number, data).subscribe(() => {
    //   this.mdDialogRef.close(true);
    //   this.ae2FeedbackService.success({
    //     message: 'Assinatura alterada com sucesso',
    //     format: Ae2FeedbackFormat.Notification
    //   });
    // }, res => {
    //   this.mdDialogRef.close(true);
    //   this.ae2HttpResponseMessagesService.createFeedback(res);
    // });
  }


}
