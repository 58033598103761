import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuDefaultModule } from '../menu/default/default.module';
import { UnitSelectionModule } from '../unit-selection/unit-selection.module';
import { Ae2HeaderWidgetModule } from '@angularecommerce/core/components/header-widget';
import { Ae2RouterClassesModule } from '@angularecommerce/core/directives/router-classes';
import { Ae2SignInTopModule } from '@angularecommerce/core/components/sign-in-top';
import { Ae2SignUpTopModule } from '@angularecommerce/core/components/sign-up-top';
import { Ae2ForgotPasswordTopModule } from '@angularecommerce/core/components/forgot-password-top';
import { Ae2ForgotPasswordDoneTopModule } from '@angularecommerce/core/components/forgot-password-done-top';
import { Ae2UnitSelectorModule } from '@angularecommerce/core/components/unit-selector';
import { Ae2SiteSettingsModule } from '@angularecommerce/core/components/site-settings';
import { MatIconModule, MatDividerModule, MatExpansionModule, MatButtonModule } from '@angular/material';
import { Ae2BagModule } from '@angularecommerce/core/components/bag';

@NgModule({
  imports: [
    FlexLayoutModule,
    CommonModule,
    RouterModule,
    Ae2SiteSettingsModule,
    MenuDefaultModule,
    Ae2RouterClassesModule,
    Ae2HeaderWidgetModule,
    Ae2SignInTopModule,
    Ae2SignUpTopModule,
    Ae2ForgotPasswordTopModule,
    Ae2ForgotPasswordDoneTopModule,
    Ae2UnitSelectorModule,
    UnitSelectionModule,
    MatIconModule,
    MatDividerModule,
    MatExpansionModule,
    MatButtonModule,
    Ae2BagModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule { }
