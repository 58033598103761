import { Component, OnInit, Inject } from '@angular/core';
import { Ae2UserModel, Ae2UserVaccineAddPayloadModel } from '@angularecommerce/core/services/users';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Ae2DialogResponse } from '@angularecommerce/core/classes/dialog-response';
import { Ae2HttpResponseMessagesService } from '@angularecommerce/core/services/http-response-messages';
import { Ae2FeedbackFormat, Ae2FeedbackService } from '@angularecommerce/core/services/feedback';
import { Ae2DateTime } from '@angularecommerce/core/classes/datetime/datetime';
import { Ae2UserService } from '@angularecommerce/core/services/users/users.service';
import { Store, select } from '@ngrx/store';
import { Ae2AuthSetSessionAction, Ae2AuthUpdateEffectAction } from '@angularecommerce/core/stores/auth/auth-actions';
import { Ae2AlertDialogData } from '@angularecommerce/core/services/dialog/dialog.model';
import { Ae2DialogService } from '@angularecommerce/core/services/dialog/dialog.service';

@Component({
    selector: 'app-other-instructor',
    templateUrl: './other-instructor.component.html',
    styleUrls: ['./other-instructor.component.sass'],
    providers: [Ae2UserService]
})
export class OtherInstructorComponent implements OnInit {

    event: any;

    constructor(
        private mdDialogRef: MatDialogRef<OtherInstructorComponent>,
        @Inject(MAT_DIALOG_DATA) private mdDialogData: any,
    ) {

    }

    ngOnInit(): void {
        this.event = this.mdDialogData.event;
    }

    close(): void {
        this.mdDialogRef.close(new Ae2DialogResponse(false));
    }


}
