<ng-container *ngIf="openInComponent">
  <a class="nameUnit" (click)="openPopOver()">
    reservar aulas
  </a>
</ng-container>
<ng-container *ngIf="!openInComponent">
  <a class="nameUnit">
    reservar aulas
  </a>
</ng-container>
