/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unit-selection.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./unit-selection.component";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@ngrx/store";
import * as i6 from "@angular/flex-layout/flex";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i15 from "@angular/material/icon";
import * as i16 from "@angular/platform-browser";
import * as i17 from "@angular/router";
import * as i18 from "@angularecommerce/core/services/unit-selector/unit-selector.service";
import * as i19 from "@angularecommerce/core/services/activities/activities.service";
import * as i20 from "@angularecommerce/core/services/units/units.service";
import * as i21 from "@angularecommerce/core/services/dialog/dialog.service";
var styles_UnitSelectiotButtomComponent = [i0.styles];
var RenderType_UnitSelectiotButtomComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnitSelectiotButtomComponent, data: {} });
export { RenderType_UnitSelectiotButtomComponent as RenderType_UnitSelectiotButtomComponent };
function View_UnitSelectiotButtomComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "nameUnit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPopOver() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" reservar aulas "]))], null, null); }
function View_UnitSelectiotButtomComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "nameUnit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" reservar aulas "]))], null, null); }
export function View_UnitSelectiotButtomComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitSelectiotButtomComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitSelectiotButtomComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openInComponent; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.openInComponent; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_UnitSelectiotButtomComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unit-selection-component", [], null, null, null, View_UnitSelectiotButtomComponent_0, RenderType_UnitSelectiotButtomComponent)), i1.ɵdid(1, 245760, null, 0, i3.UnitSelectiotButtomComponent, [i1.ElementRef, i4.Overlay, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnitSelectiotButtomComponentNgFactory = i1.ɵccf("app-unit-selection-component", i3.UnitSelectiotButtomComponent, View_UnitSelectiotButtomComponent_Host_0, { labelInButtom: "labelInButtom", openInComponent: "openInComponent" }, {}, []);
export { UnitSelectiotButtomComponentNgFactory as UnitSelectiotButtomComponentNgFactory };
var styles_UnitSelectorComponent = [i0.styles];
var RenderType_UnitSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnitSelectorComponent, data: {} });
export { RenderType_UnitSelectorComponent as RenderType_UnitSelectorComponent };
function View_UnitSelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "unitVelo-activity"]], [[2, "unitVelo-selectedActivity", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setActivity(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activitySeleted == ((_v.context.$implicit == null) ? null : _v.context.$implicit.id)); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 2, 0, currVal_1); }); }
function View_UnitSelectorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModalKore(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "unitVelo-item"]], [[2, "unitVelo-item-selected", null], [2, "unitVelo-item-selected-fb", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.selectedUnit == null) ? null : _co.selectedUnit.id) == ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.id)) && !_co.isChangeLocation); var currVal_1 = (((((_co.selectedUnit == null) ? null : _co.selectedUnit.id) == ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.id)) && !_co.isChangeLocation) && _co.isFullBody); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.name); _ck(_v, 4, 0, currVal_2); }); }
function View_UnitSelectorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.storeChange(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "unitVelo-item"]], [[2, "unitVelo-item-selected", null], [2, "unitVelo-item-selected-fb", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.selectedUnit == null) ? null : _co.selectedUnit.id) == ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.id)) && !_co.isChangeLocation); var currVal_1 = (((((_co.selectedUnit == null) ? null : _co.selectedUnit.id) == ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.id)) && !_co.isChangeLocation) && _co.isFullBody); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.name); _ck(_v, 3, 0, currVal_2); }); }
function View_UnitSelectorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitSelectorComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["notFullbody", 2]], null, 0, null, View_UnitSelectorComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFullBody; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_UnitSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "unitVelo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "unitVelo-box"], ["fxLayout", ""], ["fxLayout.xs", "column"], ["fxLayoutAlign", "space-between center"], ["fxLayoutGap.xs", "20px"]], null, null, null, null, null)), i1.ɵdid(3, 737280, null, 0, i6.LayoutDirective, [i7.MediaMonitor, i1.ElementRef, i7.StyleUtils], { layout: [0, "layout"], layoutXs: [1, "layoutXs"] }, null), i1.ɵdid(4, 1785856, null, 0, i6.LayoutGapDirective, [i7.MediaMonitor, i1.ElementRef, [6, i6.LayoutDirective], i1.NgZone, i8.Directionality, i7.StyleUtils], { gapXs: [0, "gapXs"] }, null), i1.ɵdid(5, 737280, null, 0, i6.LayoutAlignDirective, [i7.MediaMonitor, i1.ElementRef, [6, i6.LayoutDirective], i7.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["fxLayout", ""], ["fxLayoutAlign", "start flex-end"], ["fxLayoutGap", "5px"]], null, null, null, null, null)), i1.ɵdid(7, 737280, null, 0, i6.LayoutDirective, [i7.MediaMonitor, i1.ElementRef, i7.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(8, 1785856, null, 0, i6.LayoutGapDirective, [i7.MediaMonitor, i1.ElementRef, [6, i6.LayoutDirective], i1.NgZone, i8.Directionality, i7.StyleUtils], { gap: [0, "gap"] }, null), i1.ɵdid(9, 737280, null, 0, i6.LayoutAlignDirective, [i7.MediaMonitor, i1.ElementRef, [6, i6.LayoutDirective], i7.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "unitVelo-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "unitVelo-change"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLocation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["mudar cidade"])), (_l()(), i1.ɵeld(14, 0, null, null, 5, "div", [["fxLayout", ""], ["fxLayoutAlign", "end center"], ["fxLayoutGap", "10px"]], null, null, null, null, null)), i1.ɵdid(15, 737280, null, 0, i6.LayoutDirective, [i7.MediaMonitor, i1.ElementRef, i7.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(16, 1785856, null, 0, i6.LayoutGapDirective, [i7.MediaMonitor, i1.ElementRef, [6, i6.LayoutDirective], i1.NgZone, i8.Directionality, i7.StyleUtils], { gap: [0, "gap"] }, null), i1.ɵdid(17, 737280, null, 0, i6.LayoutAlignDirective, [i7.MediaMonitor, i1.ElementRef, [6, i6.LayoutDirective], i7.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitSelectorComponent_2)), i1.ɵdid(19, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitSelectorComponent_3)), i1.ɵdid(21, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 2, "button", [["class", "buyButton"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buyPackeages() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(23, 180224, null, 0, i10.MatButton, [i1.ElementRef, i11.Platform, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["comprar aulas"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = "column"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "20px"; _ck(_v, 4, 0, currVal_2); var currVal_3 = "space-between center"; _ck(_v, 5, 0, currVal_3); var currVal_4 = ""; _ck(_v, 7, 0, currVal_4); var currVal_5 = "5px"; _ck(_v, 8, 0, currVal_5); var currVal_6 = "start flex-end"; _ck(_v, 9, 0, currVal_6); var currVal_8 = ""; _ck(_v, 15, 0, currVal_8); var currVal_9 = "10px"; _ck(_v, 16, 0, currVal_9); var currVal_10 = "end center"; _ck(_v, 17, 0, currVal_10); var currVal_11 = _co.activities; _ck(_v, 19, 0, currVal_11); var currVal_12 = _co.unitList; _ck(_v, 21, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_7 = ((_co.selectedUnit == null) ? null : ((_co.selectedUnit.locationDetail == null) ? null : _co.selectedUnit.locationDetail.name)); _ck(_v, 11, 0, currVal_7); var currVal_13 = (i1.ɵnov(_v, 23).disabled || null); var currVal_14 = (i1.ɵnov(_v, 23)._animationMode === "NoopAnimations"); _ck(_v, 22, 0, currVal_13, currVal_14); }); }
function View_UnitSelectorComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLocationValue(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "unitVelo-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.location == null) ? null : _v.context.$implicit.location.name)); _ck(_v, 4, 0, currVal_0); }); }
function View_UnitSelectorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "unitVelo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "unitVelo-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["fxLayoutAlign", "start center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeLocation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 737280, null, 0, i6.LayoutAlignDirective, [i7.MediaMonitor, i1.ElementRef, [8, null], i7.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(6, 638976, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["keyboard_arrow_left"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "unitVelo-titlee"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitSelectorComponent_7)), i1.ɵdid(11, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "start center"; _ck(_v, 4, 0, currVal_0); _ck(_v, 6, 0); var currVal_3 = _co.unitGroup; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 6).inline; _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co.selectedUnit == null) ? null : ((_co.selectedUnit.locationDetail == null) ? null : _co.selectedUnit.locationDetail.name)); _ck(_v, 9, 0, currVal_2); }); }
export function View_UnitSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitSelectorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitSelectorComponent_6)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.changeLocal; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.changeLocal; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_UnitSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unit-selector", [], null, null, null, View_UnitSelectorComponent_0, RenderType_UnitSelectorComponent)), i1.ɵdid(1, 245760, null, 0, i3.UnitSelectorComponent, [i16.DomSanitizer, i17.Router, i18.Ae2UnitSelectorService, i19.Ae2ActivitiesService, i5.Store, i20.Ae2UnitsService, i1.ChangeDetectorRef, i21.Ae2DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnitSelectorComponentNgFactory = i1.ɵccf("app-unit-selector", i3.UnitSelectorComponent, View_UnitSelectorComponent_Host_0, {}, {}, []);
export { UnitSelectorComponentNgFactory as UnitSelectorComponentNgFactory };
