<ae2-dialog-header [title]="'Detalhes desta aula'"></ae2-dialog-header>

<mat-dialog-content fxLayout="column" fxLayoutGap="10px">
    
    <h3>Esta aula contará com a presença de:</h3>

    <div fxLayout fxLayoutAlign="start center" class="line">
        <div class="image">
            <img class="img" src="{{event?.instructorDetail?.smallImage}}" />
        </div>
        <div class="name">
            {{event?.instructorDetail?.nickname || event?.instructorDetail?.fullName}}
        </div>
    </div>

    <ng-container *ngFor="let item of event?.instructorOthersDetail">
        <div fxLayout fxLayoutAlign="start center" class="line">
            <div class="image">
                <img class="img" src="{{item?.smallImage}}" />
            </div>
            <div class="name">
                {{item?.nickname || item?.fullName}}
            </div>
        </div>
    </ng-container>
    
</mat-dialog-content>

<mat-dialog-actions fxLayout="column" fxLayoutGap="10px">
    <div fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="close()">Fechar</button>
    </div>
</mat-dialog-actions>