import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {

  constructor(
    private mdDialogRef: MatDialogRef<ContactComponent>
  ) {
    
  }

  ngOnInit(): void {
  }

  close(): void {
    this.mdDialogRef.close();
  }
}
