import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs/Subject';
import { Ae2AuthState, ae2AuthStoreSelector, ae2AuthErrorStoreSelector } from '@angularecommerce/core/stores/auth';
import { Ae2AuthLogoutEffectAction, Ae2AuthUpdateEffectAction } from '@angularecommerce/core/stores/auth/auth-actions';
import { Ae2ErrorState } from '@angularecommerce/core/stores/error-state';
import { Ae2FeedbackService, Ae2FeedbackFormat } from '@angularecommerce/core/services/feedback';
import { takeUntil, filter, skip, take } from 'rxjs/operators';
import { TranslationService } from 'angular-l10n';
import { UnitSelectiotButtomComponent } from '../../unit-selection/unit-selection.component';
import { Ae2UserModel } from '@angularecommerce/core/services/users';
import { Ae2DateTime } from '@angularecommerce/core/classes/datetime/datetime';
import { Ae2AlertDialogData, Ae2DialogService } from '@angularecommerce/core/services/dialog';
import { Ae2DialogResponse } from '@angularecommerce/core/classes/dialog-response/dialog-response';
import { CovidModalComponent } from '../../covid-modal/covid-modal.component';
import { Ae2UnitSelectorState, ae2UnitSelectorStoreSelector } from '@angularecommerce/core/stores/unit-selector/';
import { Ae2UnitModel } from '@angularecommerce/core/services/units';
import { Router, Event, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-menu-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.sass'],
  providers: [UnitSelectiotButtomComponent]
})
export class MenuDefaultComponent implements OnInit {

  authStore$: Observable<Ae2AuthState>;

  authErrorStore$: Observable<Ae2ErrorState>;

  isLoggedIn: boolean;

  @Input() subMenuStatus: boolean;

  @Input() authStore: Ae2AuthState;

  @Output() openSignIn: EventEmitter<boolean> = new EventEmitter();

  @Output() openSignUp: EventEmitter<boolean> = new EventEmitter();

  unit: Ae2UnitModel;

  isShowButtonMask: boolean = false;

  private unitSelectorStore$: Observable<Ae2UnitSelectorState>;

  private destroy$: Subject<any> = new Subject();


  constructor(protected store: Store<any>,
    protected ae2FeedBackService: Ae2FeedbackService,
    private translationService: TranslationService,
    private unitSelectiotButtomComponent: UnitSelectiotButtomComponent,
    private ae2DialogService: Ae2DialogService,
    private router: Router
  ) {
    this.authStore$ = this.store.pipe(select(ae2AuthStoreSelector));
    this.authErrorStore$ = this.store.pipe(select(ae2AuthErrorStoreSelector));
    this.unitSelectorStore$ = store
      .pipe(
        select(ae2UnitSelectorStoreSelector),
        takeUntil(this.destroy$)
      );
  }

  ngOnInit(): void {
    this.authStore$.subscribe(state => {
      this.isLoggedIn = !!state;
      this.authStore = state;
      this.checkShowMaskButton();
    });
    this.unitSelectorStore$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res) {
          this.unit = res.unit;
          this.checkShowMaskButton();
        }
      });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.checkShowMaskButton();
      }
    })
  }

  checkShowMaskButton(): void {
    if (!this.unit) {
      return;
    }
    this.isShowButtonMask = this.unit.vaccineNeeded && this.router.url.indexOf('agenda') === 1 ? true : false;
  }

  openInSignIn(): void {
    this.openSignIn.emit();
  }

  openInSignUp(): void {
    this.openSignUp.emit();
  }

  logOut(): void {
    this.store.dispatch(new Ae2AuthLogoutEffectAction());

    const destroy$ = new Subject();

    // skip é para nao rodar imaditamente
    this.authStore$
      .pipe(
        takeUntil(destroy$),
        skip(1),
        take(1)
      )
      .subscribe(() => {
        this.ae2FeedBackService.success({
          message: this.translationService.translate('core.global.messages.users.signOutSuccess'),
          format: Ae2FeedbackFormat.Notification,
          duration: 2000
        });
        destroy$.next();
      });

    // caso dê erro ao fazer logout, remove a subscription pra evitar erros
    this.authErrorStore$
      .pipe(
        takeUntil(destroy$),
        filter(state => state && state.type instanceof Ae2AuthLogoutEffectAction),
        take(1)
      )
      .subscribe(() => {
        destroy$.next();
      });
  }

  openPopOver(): void {
    this.unitSelectiotButtomComponent.openPopOver();
  }

  goToPage(link: string): void {
    window.open(link, '_blank');
  }

  openModalCovid(): void {
    this.ae2DialogService
      .openCustomDialog(CovidModalComponent, {
        width: '600px',
        data: {
          user: this.authStore.user,
        }
      })
      .subscribe(res => {
        if (!res.answer) {
          if (!this.authStore.user.vaccineImage) {
            const data: Ae2AlertDialogData = new Ae2AlertDialogData();
            data.title = 'Vacinação COVID-19';
            data.message = "Devido a legislação vigente não poderemos fazer seu checkin caso não haja comprovante de vacinação cadastrado no sistema. Se preferir poderá apresentá-lo a nossa recepção no momento do checkin.";
            data.width = '600';
            this.ae2DialogService.openAlertDialog(data);
            return;
          }
        }
      });
  }

  validDate(user: Ae2UserModel): boolean {
    if (user && !user.vaccineDate) {
      return true;
    }
    const date = new Ae2DateTime(user.vaccineDate);
    return new Ae2DateTime(date).isBefore(new Ae2DateTime());
  }

  colorButtonFloat(): string {
    if (!this.authStore.user.vaccineImage) {
      return 'borderColorDanger'
    }
    if ((!this.authStore.user.vaccineDate && this.authStore.user.vaccineImage)) {
      return 'borderColorWarn'
    }
    if (this.isExpired()) {
      return 'borderColorDanger'
    }
    if ((this.authStore.user.vaccineDate && this.authStore.user.vaccineImage) && this.isExpired() == false) {
      return 'borderColorSuccess'
    }
  }

  isExpired() {
    if (this.authStore.user.vaccineDate) {
      return new Ae2DateTime(this.authStore.user.vaccineDate).format('YYYY-MM-DD') < new Ae2DateTime().format('YYYY-MM-DD')
    }
    return null

  }

}
