import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { Ae2CarouselContainerModule } from '@angularecommerce/core/components/carousel-container';
import { Ae2SeoModule } from '@angularecommerce/core/directives/seo/seo.module';

@NgModule({
  imports: [
    CommonModule,
    Ae2SeoModule,
    Ae2CarouselContainerModule
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
