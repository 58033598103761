<footer class="f">
  <ae2-site-settings>
    <ng-template ae2SiteSettingsContent let-site="site">
      <section class="f-s" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center">

          <div class="f-s-c" fxFlex="60%" fxFlex.xs="auto" fxLayout fxLayout.xs="column" fxLayoutAlign="baseline center">
            <div class="f-s-c-l"></div>
            <mat-nav-list fxLayout fxLayout.xs="column" fxLayoutAlign.xs="center center">
              <a class="f-s-c-li" mat-list-item routerLink="/sobre/velocity" fxLayoutAlign="center center">sobre</a>
              <a class="f-s-c-li" mat-list-item routerLink="/faq" fxLayoutAlign="center center">faq</a>
              <!-- <a class="f-s-c-li" mat-list-item routerLink="/imprensa">imprensa</a> -->
              <!-- <a class="f-s-c-li" mat-list-item routerLink="/carreiras">carreiras</a> --> 
              <!-- <a class="f-s-c-li" mat-list-item routerLink="/parceiros">parcerias corporativas</a> -->
              <a class="f-s-c-li" mat-list-item (click)="openContact()" fxLayoutAlign="center center">contato</a>
              <a class="f-s-c-li" mat-list-item routerLink="/carreiras" fxLayoutAlign="center center">carreiras</a>
              <a class="f-s-c-li" mat-list-item (click)="targetLint('https://www.franquiastudiovelocity.com.br/')" fxLayoutAlign="center center">franquias</a>
              <a class="f-s-c-li" mat-list-item (click)="targetLint('https://www.studiokore.com.br/')" fxLayoutAlign="center center">conheça o kore</a>
              <a class="f-s-c-li" mat-list-item routerLink="/regimento-interno" fxLayoutAlign="center center">regimento interno</a>
              <a class="f-s-c-li" mat-list-item routerLink="/politica-de-privacidade" fxLayoutAlign="center center">política de privacidade</a>
              <!-- <a class="f-s-c-li" mat-list-item routerLink="/velocity-eventos" fxLayoutAlign="center center">velonews</a> -->
            </mat-nav-list>
          </div>

          <div class="f-s-c-se" fxLayout fxLayout.xs="column" fxFlex="40%" fxFlex.xs="auto" fxLayoutAlign="flex-end center">
            <div class="plays" fxLayout fxLayout.xs="column" fxLayoutAlign="center center">
              <div class="image-lojas2">
                <img class="image-lojas2" src="../../../../assets/images/selo-velo.png" >
              </div>
              <!-- <a href="https://play.google.com/store/apps/details?id=com.studiovelocity.br" target="_blank">
                <img class="image-lojas" src="../../../../assets/images/getPlay.png" alt="aplicativo studio velocity no google play">
              </a> -->
               <!-- <a (click)="openModalGooglePlay()">
                <img class="image-lojas" src="../../../../assets/images/getPlay.png" alt="aplicativo studio velocity no google play">
              </a> -->
              <a href="https://apps.apple.com/br/app/studio-velocity-bike-indoor/id1485886327" target="_blank">
                <img class="image-lojas" src="../../../../assets/images/getApple.png" alt="aplicativo studio velocity no google play">
              </a>
            </div>
            <div class="f-s-c-s" matLine *ngIf="site.contactEmail" (click)="targetLint('mailto:'+site.contactEmail)">
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
            </div>
            <div class="f-s-c-s" matLine *ngIf="site.socialTwitter" (click)="targetLint(site.socialTwitter)">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </div>
            <div class="f-s-c-s" matLine *ngIf="site.socialFacebook" (click)="targetLint(site.socialFacebook)">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </div>
            <div class="f-s-c-s" matLine *ngIf="site.socialInstagram" (click)="targetLint(site.socialInstagram)">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </div>
            <div class="f-s-c-s" matLine *ngIf="site.socialSpotify" (click)="targetLint(site.socialSpotify)">
              <i class="fa fa-spotify" aria-hidden="true"></i>
            </div>
            <div class="f-s-c-s" matLine *ngIf="site.socialYoutube" (click)="targetLint(site.socialYoutube)">
              <i class="fa fa-youtube" aria-hidden="true"></i>
            </div>
            <div class="f-s-c-s" matLine *ngIf="site.socialLinkedin" (click)="targetLint(site.socialLinkedin)">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </div>
            <div class="f-s-c-s" matLine *ngIf="site.socialVimeo" (click)="targetLint(site.socialVimeo)">
              <i class="fa fa-vimeo" aria-hidden="true"></i>
            </div>
            <div class="f-s-c-s" matLine *ngIf="site.socialFlickr" (click)="targetLint(site.socialFlickr)">
              <i class="fa fa-flickr" aria-hidden="true"></i>
            </div>
          </div>

      </section>
      <section class="f-d" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
        <div>©
          <strong>Velocity</strong> Academia de Ginastica Ltda
          <!-- <strong>Velocity</strong> Academia de Ginastica Ltda | -->
          <!-- <a class="link" href="/media/terms-and-conditions/termos-e-condicoes-velocity_wBhLoxX.pdf" target="_blank">Termos de Uso</a> -->
        </div>
        <div>
          <a class="link" href="https://angulare.app/?utm_source=cws&utm_campaign=velocity" target="_blank">Powered by Angular e-Commerce</a>
        </div>
      </section>
    </ng-template>
  </ae2-site-settings>
</footer>