/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angularecommerce/core/directives/seo/seo.component.ngfactory";
import * as i3 from "@angularecommerce/core/directives/seo/seo.component";
import * as i4 from "@angularecommerce/core/directives/seo/seo.service";
import * as i5 from "../../../../node_modules/@angularecommerce/core/components/carousel-container/carousel-container.component.ngfactory";
import * as i6 from "@angularecommerce/core/components/carousel-container/carousel-container.component";
import * as i7 from "@angularecommerce/core/services/carousel-banners/carousel-banners.service";
import * as i8 from "./home.component";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ae2-seo", [], null, null, null, i2.View_Ae2SeoComponent_0, i2.RenderType_Ae2SeoComponent)), i1.ɵdid(1, 114688, null, 0, i3.Ae2SeoComponent, [i4.Ae2SeoService], { setTitle: [0, "setTitle"], setMetaDescription: [1, "setMetaDescription"], setKeyword: [2, "setKeyword"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ae2-carousel-container", [["carouselCode", "home"]], null, null, null, i5.View_Ae2CarouselContainerComponent_0, i5.RenderType_Ae2CarouselContainerComponent)), i1.ɵdid(3, 49152, null, 1, i6.Ae2CarouselContainerComponent, [i7.Ae2CarouselBannersService, i1.ChangeDetectorRef], { config: [0, "config"], carouselCode: [1, "carouselCode"] }, null), i1.ɵqud(335544320, 1, { templateRef: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Studio Velocity | M\u00FAsica, alma, suor (tudo junto)"; var currVal_1 = "\n    Studio Velocity \u00E9 mais do que uma aula de bike indoor. \u00C9 conex\u00E3o entre corpo, mente, m\u00FAsica e movimento. S\u00E3o 45min de cardio que transformam de dentro pra fora."; var currVal_2 = "Studio Velocity"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.carouselConfig; var currVal_4 = "home"; _ck(_v, 3, 0, currVal_3, currVal_4); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i8.HomeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i8.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
