var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Ae2MyAccountGlobalConfig, MenuInterface } from '@angularecommerce/core/components/my-account';
var MyAccountCustomConfig = /** @class */ (function (_super) {
    __extends(MyAccountCustomConfig, _super);
    function MyAccountCustomConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.titleEvents = 'Minhas Reservas';
        _this.showFTP = false;
        _this.menu = [
            { name: 'minhas Reservas', link: 'meus-eventos', icon: 'event' },
            { name: 'meus créditos', link: 'meus-creditos', icon: 'account_balance' },
            { name: 'meus pedidos', link: 'meus-pedidos', icon: 'bookmark_border' },
            { name: 'minhas assinaturas', link: 'minhas-assinaturas', icon: 'bookmark' },
            { name: 'cartões de crédito', link: 'metodos-pagamento', icon: 'credit_card' },
            { name: 'meus dados', link: 'meus-dados', icon: 'account_circle' },
            { name: 'alterar senha', link: 'alterar-senha', icon: 'lock' },
            { name: 'meus endereços', link: 'meus-enderecos', icon: 'room' },
            { name: 'dados de performance', link: 'dados-de-performance', icon: 'timeline' }
        ];
        return _this;
    }
    return MyAccountCustomConfig;
}(Ae2MyAccountGlobalConfig));
export { MyAccountCustomConfig };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
