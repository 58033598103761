import { Component, OnInit, Inject } from '@angular/core';
import { Ae2UserModel, Ae2UserVaccineAddPayloadModel } from '@angularecommerce/core/services/users';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Ae2DialogResponse } from '@angularecommerce/core/classes/dialog-response';
import { Ae2HttpResponseMessagesService } from '@angularecommerce/core/services/http-response-messages';
import { Ae2FeedbackFormat, Ae2FeedbackService } from '@angularecommerce/core/services/feedback';
import { Ae2DateTime } from '@angularecommerce/core/classes/datetime/datetime';
import { Ae2UserService } from '@angularecommerce/core/services/users/users.service';
import { Store, select } from '@ngrx/store';
import { Ae2AuthSetSessionAction, Ae2AuthUpdateEffectAction } from '@angularecommerce/core/stores/auth/auth-actions';
import { Ae2AlertDialogData } from '@angularecommerce/core/services/dialog/dialog.model';
import { Ae2DialogService } from '@angularecommerce/core/services/dialog/dialog.service';
import { Ae2GiftCardModel, Ae2RedeemGiftCardModel } from '@angularecommerce/core/services/sales-order/sales-order.model';
import { Ae2SalesOrderService } from '@angularecommerce/core/services/sales-order';
import {debounceTime} from 'rxjs/operators';
import {pipe} from 'rxjs'

@Component({
    selector: 'app-change-gift',
    templateUrl: './change-gift.component.html',
    styleUrls: ['./change-gift.component.sass'],
    providers: [Ae2UserService]
})
export class ChangeGiftComponent implements OnInit {

    form: FormGroup;

    isLoading: boolean = false;

    gift: Ae2GiftCardModel;

    constructor(
        private mdDialogRef: MatDialogRef<ChangeGiftComponent>,
        @Inject(MAT_DIALOG_DATA) private mdDialogData: any,
        private ae2HttpResponseMessagesService: Ae2HttpResponseMessagesService,
        private ae2FeedbackService: Ae2FeedbackService,
        private ae2UserService: Ae2UserService,
        private store: Store<any>,
        private ae2DialogService: Ae2DialogService,
        private ae2SalesOrderService: Ae2SalesOrderService,
    ) {

    }

    ngOnInit(): void {
        this.gift = this.mdDialogData.gift;
        this.form = this.createForm();
        this.form.get('sendAfter').valueChanges
        .pipe(debounceTime(100))
        .subscribe(value => {
            if (value.length === 16) {
                const dateCheck = `${value.substr(6, 4)}-${value.substr(3, 2)}-${value.substr(0, 2)} ${value.substr(11, 5)}`
                const check = new Ae2DateTime(dateCheck).isAfter(Ae2DateTime.now());
                if (!check) {
                    this.form.get('sendAfter').setValue(Ae2DateTime.now().format('DD/MM/YYYY HH:mm'));
                }
            }
          });
    }

    private createForm(): FormGroup {
        return new FormGroup({
            recipientEmail: new FormControl(this.gift.recipientEmail, Validators.required),
            sendAfter: new FormControl(this.gift.sendAfter ? new Ae2DateTime(this.gift.sendAfter).format('DD/MM/YYYY HH:mm') : ''),
            recipientMessage: new FormControl(this.gift.recipientMessage),
        });
    }

    close(): void {
        this.mdDialogRef.close(new Ae2DialogResponse(false));
    }

    submit(): void {
        const data: Ae2RedeemGiftCardModel = new Ae2RedeemGiftCardModel();
        data.recipientEmail = this.form.get('recipientEmail').value;
        const after = this.form.get('sendAfter').value;
        data.sendAfter = after ? `${after.substr(6, 4)}-${after.substr(3, 2)}-${after.substr(0, 2)} ${after.substr(11, 5)}` : null;
        data.recipientMessage = this.form.get('recipientMessage').value;
        this.ae2SalesOrderService.pathGiftCards(this.gift.token, data).subscribe(res => {
            this.ae2FeedbackService.success({
                message: 'Seu giftcard foi alterado com sucesso',
                format: Ae2FeedbackFormat.Notification
              });
            this.mdDialogRef.close(new Ae2DialogResponse(true, {...this.gift, ...res}));
        }, error => {
            this.ae2HttpResponseMessagesService.createFeedback(error);
            this.isLoading = false;
            this.mdDialogRef.close(new Ae2DialogResponse(false));
        });
    }

}
