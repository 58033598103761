<ae2-dialog-header
  [title]="step === 1 ? 'Solicitar cancelamento de assinatura' : step === 2 ? 'Motivo do cancelamento' : step === 3 ? 'Sentiremos sua falta!' : step === 4 ? 'Erro no cancelamento' : ''">
</ae2-dialog-header>

<mat-dialog-content>
  <!-- <form class="example-form" [formGroup]="form"> -->
  <div class="content">
    <ng-container *ngIf="step === 1">
      <p>Esta ação cancelará sua assinatura. Note que:</p>
      <p>1) Essa ação é irreversível;<br>
        2) Se você possuir algum benefício o mesmo será cancelado;<br>
        3) Você poderá fazer aulas até o término da vigência da sua assinatura;<br>
        4) Novos valores não serão cobrados, porém valores já cobrados não serão reembolsados;</p>
      <p>Deseja continuar?</p>
    </ng-container>
    <ng-container *ngIf="step === 2">
      <form [formGroup]="form">
        <p>Nos informe o motivo do seu cancelamento</p>
        <mat-form-field class="full">
          <textarea matInput formControlName="cancel_reason"></textarea>
        </mat-form-field>

      </form>
    </ng-container>
    <ng-container *ngIf="step === 3">
      <p>Cancelamento realizado com sucesso, mas queremos você novamente conosco!</p>
    </ng-container>

    <ng-container *ngIf="step === 4">
      <p>O cancelamento não pôde ser realizado. O motivo relatado foi: {{reason}}</p>
    </ng-container>
  </div>
  <!-- </form> -->
</mat-dialog-content>

<mat-dialog-actions>
  <div fxLayoutAlign="space-between center" class="action">
    <button mat-raised-button color="primary" mat-dialog-close>{{step < 3 ? 'Cancelar' : 'Fechar' }}</button>
        <ng-container *ngIf="step < 3">
          <button [disabled]="step === 2 && form.invalid" mat-raised-button (click)="submit()">Continuar</button>
        </ng-container>
  </div>
</mat-dialog-actions>