<ae2-dialog-header [title]="'Cadastro de comprovante de vacinação COVID-19'"></ae2-dialog-header>

<mat-dialog-content fxLayout="column" fxLayoutGap="10px">
    <ng-container *ngIf="!user?.vaccineImage">
        <p>De acordo com as normas municipais vigentes é necessário enviar o comprovante de vacinação para realizar atividades
            presenciais neste estabelecimento.</p>
    </ng-container>
    <ng-container *ngIf="!user?.vaccineDate && user?.vaccineImage">
        <p>Comprovante de vacinação COVID-19 enviado e pendente de validação. Chegue mais cedo na próxima aula, pois a
            validação será feita na recepção, assim que você chegar.</p>
    </ng-container>
    <ng-container *ngIf="user?.vaccineDate && validDate(user?.vaccineDate)">
        <p>É necessário cadastrar novo comprovante da sua vacina. Clique na foto para fazer isso agora!</p>
    </ng-container>
    <ng-container *ngIf="validateCheck(user?.vaccineDate) && user?.vaccineImage">
        <p>O cadastro de sua vacinação para COVID-19 está em dia até {{user?.vaccineDate |
            ae2DateTimeFormat:'shortDate'}}</p>
    </ng-container>

    <div>
        <ng-container *ngIf="image && image.indexOf('application/pdf') >= 1">
            <p>Arquivo PDF não suporta pré-visualização</p>
        </ng-container>
        <ng-container *ngIf="image && image.indexOf('application/pdf') < 0">
            <img class="image" src="{{image}}">
        </ng-container>
        <div fxLayout fxLayout.xs="column" class="line" fxLayoutAlign="center center" fxLayoutAlign.xs="start start">
            <label fxFlex="30%" fxFlex.xs="auto" class="labelCustom">Comprovante</label>
            <input fxFlex="70%" fxFlex.xs="auto" accept="image/jpeg, image/png, application/pdf" id="inputfile" type="file"
                ae2FileUploader (upload)="onUpload($event)" [class.custom-file-input2]="image"
                [class.custom-file-input]="!image" />
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions fxLayout="column" fxLayoutGap="10px">
    <div fxLayoutAlign="space-between center" class="action">
        <button mat-button mat-dialog-close>Descartar</button>
        <ng-container *ngIf="!validateCheck(user?.vaccineDate) || !user?.vaccineImage">
            <button mat-raised-button color="primary" [ae2ButtonLocker]="isLoading" (click)="submit()"
                [disabled]="!image || isLoading || image.substr(0, 4) === 'http'">Enviar</button>
        </ng-container>
    </div>
</mat-dialog-actions>