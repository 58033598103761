/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/router";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./contact.component";
import * as i9 from "@angular/material/dialog";
var styles_ContactComponent = [i0.styles];
var RenderType_ContactComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactComponent, data: {} });
export { RenderType_ContactComponent as RenderType_ContactComponent };
export function View_ContactComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "aside", [["class", "aec"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "aec-i"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "aec-t"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Du\u0301vidas ou comenta\u0301rios? Envie-nos um email ou ligue em um de nossos studios. "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "aec-l"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "aec-l-t"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["e-mail"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["href", "mailto:velocity@studiovelocity.com.br"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["velocity@studiovelocity.com.br"])), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "aec-l"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "aec-l-t"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["telefones"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["class", "aec-l-l"], ["mat-button", ""], ["routerLink", "/unidades"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(13, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(14, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["veja lista dos studios"]))], function (_ck, _v) { var currVal_2 = "/unidades"; _ck(_v, 13, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 14).disabled || null); var currVal_1 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_0, currVal_1); }); }
export function View_ContactComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact", [], null, null, null, View_ContactComponent_0, RenderType_ContactComponent)), i1.ɵdid(1, 114688, null, 0, i8.ContactComponent, [i9.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactComponentNgFactory = i1.ɵccf("app-contact", i8.ContactComponent, View_ContactComponent_Host_0, {}, {}, []);
export { ContactComponentNgFactory as ContactComponentNgFactory };
