<ae2-dialog-header [title]="'Reativação de Assinatura'"></ae2-dialog-header>

<mat-dialog-content>
  <!-- <form class="example-form" [formGroup]="form"> -->
  <div class="content">
    <form class="example-form" [formGroup]="form">
      <div class="text">
        A reativação da assinatura permite você agendar aulas imediatamente e você só será cobrado depois dos dias de crédito que possuir* serem consumidos. Deseja prosseguir?
      </div>
      <div class="text2">
        * os dias de crédito são calculados pelo período compreendido entre a data do congelamento da assinatura e a expiração inicial do seu pacote.
      </div>
      <!-- <mat-form-field>
        <mat-label>Data da reativação</mat-label>
        <input matInput [min]="minDate" [matDatepicker]="picker" placeholder="Data da reativação" formControlName="return_at">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

        <mat-checkbox class="example-margin" formControlName="cancel">Cancelar assinatura após terminar os dias de crédito</mat-checkbox> -->

    </form>
  </div>
  <!-- </form> -->
</mat-dialog-content>

<mat-dialog-actions>
  <div fxLayoutAlign="space-between center" class="action">
    <button mat-button mat-dialog-close>Voltar</button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="isLoading" [ae2ButtonLocker]="isLoading">Confirmar</button>
  </div>
</mat-dialog-actions>