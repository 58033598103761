import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Ae2AuthState, ae2AuthStoreSelector } from '@angularecommerce/core/stores/auth';
import { Ae2HeaderOpenSignInAction } from '@angularecommerce/core/stores/header/header-actions';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs/Subject';
import {Ae2CoreService} from '@angularecommerce/core/services/core/core-controls.service';
import { Ae2AppTesterPayloadModel } from '@angularecommerce/core/services/core/core-controls.model';
import { Ae2HttpResponseMessagesService } from '@angularecommerce/core/services/http-response-messages';
import { Ae2FeedbackFormat, Ae2FeedbackService } from '@angularecommerce/core/services/feedback';
import { Ae2AuthToken } from '@angularecommerce/core/classes/auth-token';
import { Ae2SettingsModel } from '@angularecommerce/core/services/settings/settings.model';
import { Ae2SettingsService } from '@angularecommerce/core/services/settings/settings.service';

@Component({
  selector: 'app-testers',
  templateUrl: './testers.component.html',
  styleUrls: ['./testers.component.sass'],
  providers: [Ae2CoreService]
})
export class TestersComponent implements OnInit {

  form: FormGroup;

  authStore$: Observable<Ae2AuthState>;

  isLoggedIn: boolean;

  isLoading: boolean = false;

  isMessage: string = '';

  settings: Ae2SettingsModel;

  private ae2AuthToken: Ae2AuthToken = new Ae2AuthToken();

  constructor(
    private mdDialogRef: MatDialogRef<TestersComponent>,
    private store: Store<any>,
    private ae2CoreService: Ae2CoreService,
    private ae2HttpResponseMessagesService: Ae2HttpResponseMessagesService,
    private ae2FeedbackService: Ae2FeedbackService,
    private ae2SettingsService: Ae2SettingsService,
  ) {
    this.authStore$ = this.store.pipe(select(ae2AuthStoreSelector));
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.authStore$.subscribe(state => {
      this.isLoggedIn = !!state;
    });
    this.settings = this.ae2SettingsService.settings;
  }
  private createForm(): FormGroup {
    return new FormGroup({
      google_play_user: new FormControl('', Validators.required),
    });
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }
    const data: Ae2AppTesterPayloadModel = new Ae2AppTesterPayloadModel();
    data.appCode = 'velocity';
    data.tenant = 'velocity';
    data.googlePlayUser = this.form.get('google_play_user').value;
    const jwt = `JWT ${this.ae2AuthToken.get()}`;
    const url = this.settings.site.isProduction ? 'https://crm.angulare.app/api/v1/core/apptester/' : 'https://crm.dev.angulare.app/api/v1/core/apptester/';
    this.ae2CoreService.appTester(data, url, jwt).subscribe(res => {
      this.ae2FeedbackService.success({
        message: res.message,
        format: Ae2FeedbackFormat.Notification,
        duration: 20000
      });
      this.close();
    }, error => {
      this.ae2HttpResponseMessagesService.createFeedback(error);
      this.close();
    })

  }

  close(): void {
    this.mdDialogRef.close();
  }

  loginOpen(): void {
    this.mdDialogRef.close();
    this.store.dispatch(new Ae2HeaderOpenSignInAction());
  }
}
