import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { Ae2SiteSettingsModule } from '@angularecommerce/core/components/site-settings';
import { Ae2ReplacePipeModule } from '@angularecommerce/core/pipes/replace';
import { MatListModule } from '@angular/material/list';
import { ContactModule } from '../contact/contact.module';
import { Ae2MarketingServiceModule } from '@angularecommerce/core/services/marketing';
import { MatDialogModule } from '@angular/material/dialog';
import { TestersModule } from '../testers/testers.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    ReactiveFormsModule,
    Ae2SiteSettingsModule,
    Ae2ReplacePipeModule,
    MatListModule, 
    MatDialogModule,
    ContactModule,
    TestersModule,

    Ae2MarketingServiceModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule { }
