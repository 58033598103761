import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatDialogModule,
    MatButtonModule
} from '@angular/material';
import { LocalizationModule } from 'angular-l10n';
import { CovidModalComponent } from './covid-modal.component';
import { Ae2DialogHeaderModule } from '@angularecommerce/core/components/dialog-header';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Ae2MaskModule } from '@angularecommerce/core/directives/mask';
import { Ae2ImageUploadModule } from '@angularecommerce/core/components/image-upload/image-upload.module';
import { Ae2FileUploaderModule } from '@angularecommerce/core/directives/file-uploader';
import { Ae2DateTimeFormatPipeModule } from '@angularecommerce/core/pipes/datetime';
import { Ae2ButtonLockerModule } from '@angularecommerce/core/directives/button-locker/button-locker.module';
@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatDialogModule,
        MatButtonModule,
        LocalizationModule,
        Ae2DialogHeaderModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        Ae2MaskModule,
        Ae2ImageUploadModule,
        Ae2FileUploaderModule,
        Ae2DateTimeFormatPipeModule,
        Ae2ButtonLockerModule
    ],
    declarations: [CovidModalComponent],
    entryComponents: [CovidModalComponent]
})
export class CovidModalModule { }
