<div class="relative">
    <ae2-dialog-header [title]="'Efetuar Pagamento de pedido não autorizado'"></ae2-dialog-header>

    <mat-dialog-content fxLayout="column" fxLayoutGap="10px">
        <ng-container *ngIf="error">
            <div>
                Desculpe, não foi possível realizar a transação no momento.<br>
                Verifique com o banco que emitiu seu cartão qual o motivo da autorização ter sido negada ou tente com
                outro cartão.
            </div>
        </ng-container>
        <ng-container *ngIf="!error">
            <div class="content">
                Antes de efetuar este procedimento verifique com o banco que emitiu seu cartão qual o motivo da
                autorização
                ter sido negada e somente depois confirme esta operação.
                <br><br>
                Se desejar, você pode cadastrar outro cartão de crédito antes de tentar a cobrança. Esta operação não
                será
                imediata, porém em alguns minutos você pode conferir se seu pagamento foi autorizado.
            </div>
        </ng-container>

    </mat-dialog-content>

    <mat-dialog-actions fxLayout fxLayoutGap="10px" fxLayoutAlign="space-between center">
        <div class="action">
            <button mat-raised-button color="accent" [ae2ButtonLocker]="isLoading" (click)="close()">Voltar</button>
        </div>
        <div class="action">
            <button mat-raised-button color="primary" [ae2ButtonLocker]="isLoading" (click)="openDialofCard()">Pagar com novo
                cartao</button>
        </div>
        <ng-container *ngIf="!isLoading">
            <select class="otherCard" (change)="changePayment($event)">
                <option [value]="null">--Selecione um cartão--</option>
                <option *ngFor="let item of cards" [value]="item.id">Pagar com {{item.flagDisplay}} final
                    {{item?.maskedNumber.substr(-4, 4)}}</option>
            </select>
        </ng-container>

    </mat-dialog-actions>
    <ng-container *ngIf="isLoading">
        <div class="fullPage">
            <div class="textProgress">
                Aguarde um momento enquanto processamos sua transação. Agradecemos sua paciência.
            </div>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </ng-container>
</div>