import { NgModule } from '@angular/core';
import { TestersComponent } from './testers.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Ae2ButtonLockerModule} from '@angularecommerce/core/directives/button-locker/button-locker.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    Ae2ButtonLockerModule
  ],
  declarations: [TestersComponent],
  exports: [TestersComponent],
  entryComponents: [TestersComponent]
})
export class TestersModule { }
