import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MenuDefaultComponent } from './default.component';
import { ScrollToModule } from 'ng2-scroll-to-el';

import { UnitSelectionModule } from '../../unit-selection/unit-selection.module';
import { Ae2UnitSelectorModule } from '@angularecommerce/core/components/unit-selector';
import { Ae2TotalCreditsModule } from '@angularecommerce/core/components/total-credits';
import { Ae2BagModule } from '@angularecommerce/core/components/bag';
import { MatIconModule, MatListModule, MatButtonModule} from '@angular/material';

@NgModule({
  imports: [
    FlexLayoutModule,
    CommonModule,
    RouterModule,
    ScrollToModule,
    Ae2BagModule,
    Ae2UnitSelectorModule,
    UnitSelectionModule,
    Ae2TotalCreditsModule,
    MatIconModule,
    MatListModule,
    MatButtonModule
  ],
  declarations: [MenuDefaultComponent],
  exports: [MenuDefaultComponent]
})
export class MenuDefaultModule { }
