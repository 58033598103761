import { Component, OnInit, Inject } from '@angular/core';
import { Ae2UserModel, Ae2UserVaccineAddPayloadModel } from '@angularecommerce/core/services/users';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Ae2DialogResponse } from '@angularecommerce/core/classes/dialog-response';
import { Ae2HttpResponseMessagesService } from '@angularecommerce/core/services/http-response-messages';
import { Ae2FeedbackFormat, Ae2FeedbackService } from '@angularecommerce/core/services/feedback';
import { Ae2DateTime } from '@angularecommerce/core/classes/datetime/datetime';
import { Ae2UserService } from '@angularecommerce/core/services/users/users.service';
import { Store, select } from '@ngrx/store';
import { Ae2AuthSetSessionAction, Ae2AuthUpdateEffectAction } from '@angularecommerce/core/stores/auth/auth-actions';
import { Ae2AlertDialogData } from '@angularecommerce/core/services/dialog/dialog.model';
import { Ae2DialogService } from '@angularecommerce/core/services/dialog/dialog.service';

@Component({
    selector: 'app-covid-modal',
    templateUrl: './covid-modal.component.html',
    styleUrls: ['./covid-modal.component.sass'],
    providers: [Ae2UserService]
})
export class CovidModalComponent implements OnInit {

    user: Ae2UserModel;

    image: string;

    isLoading: boolean = false;

    constructor(
        private mdDialogRef: MatDialogRef<CovidModalComponent>,
        @Inject(MAT_DIALOG_DATA) private mdDialogData: any,
        private ae2HttpResponseMessagesService: Ae2HttpResponseMessagesService,
        private ae2FeedbackService: Ae2FeedbackService,
        private ae2UserService: Ae2UserService,
        private store: Store<any>,
        private ae2DialogService: Ae2DialogService,
    ) {

    }

    ngOnInit(): void {
        this.user = this.mdDialogData.user;
        this.image = this.user.vaccineImage;
    }

    close(): void {
        this.mdDialogRef.close(new Ae2DialogResponse(false));
    }

    submit(): void {
        this.isLoading = true;
        if (this.image.substr(0, 4) === 'http') {
            this.mdDialogRef.close(new Ae2DialogResponse(true));
            return;
        }
        // `${this._createDateFromVisible(this.form.get('start_time').value)}`
        const data: Ae2UserVaccineAddPayloadModel = new Ae2UserVaccineAddPayloadModel(this.image);
        this.ae2UserService.vaccineAdd(this.user.id, data).subscribe(res => {
            this.store.dispatch(new Ae2AuthSetSessionAction({ user: res }));
            this.mdDialogRef.close(new Ae2DialogResponse(true, res));
            const data: Ae2AlertDialogData = new Ae2AlertDialogData();
            data.title = 'Comprovante enviado!';
            data.message = "Obrigado por tornara nosso ambiente mais seguro! Seu comprovante de vacinação será validado por nossa equipe no momento do seu checkin! Pedimos a gentileza de chegar 10 minutos antes que o usual.";
            data.width = '500';
            this.ae2DialogService.openAlertDialog(data);
        }, error => {
            this.ae2HttpResponseMessagesService.createFeedback(error);
            this.isLoading = false;
            this.mdDialogRef.close(new Ae2DialogResponse(true));
        })
    }

    validDate(date: string): boolean {
        const data = new Ae2DateTime(date);
        return new Ae2DateTime(data).isBefore(new Ae2DateTime());
    }

    validateCheck(date: string): boolean {
        if (!date) {
            return false;
        }
        const data = new Ae2DateTime(date);
        return new Ae2DateTime(data).isAfter(new Ae2DateTime());
    }

    onUpload([image]: string[]): void {
        this.image = image;
    }

    _createDateFromVisible(value: string): any {
        const date = value.replace(' ', '/').split('/');
        return `${date[2]}-${date[1]}-${date[0]} ${date[3]}`;
    }

}
