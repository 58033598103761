var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Ae2DialogResponse } from '@angularecommerce/core/classes/dialog-response';
import { Ae2HttpResponseMessagesService } from '@angularecommerce/core/services/http-response-messages';
import { Ae2FeedbackFormat, Ae2FeedbackService } from '@angularecommerce/core/services/feedback';
import { Ae2DateTime } from '@angularecommerce/core/classes/datetime/datetime';
import { Ae2UserService } from '@angularecommerce/core/services/users/users.service';
import { Store } from '@ngrx/store';
import { Ae2DialogService } from '@angularecommerce/core/services/dialog/dialog.service';
import { Ae2RedeemGiftCardModel } from '@angularecommerce/core/services/sales-order/sales-order.model';
import { Ae2SalesOrderService } from '@angularecommerce/core/services/sales-order';
import { debounceTime } from 'rxjs/operators';
var ChangeGiftComponent = /** @class */ (function () {
    function ChangeGiftComponent(mdDialogRef, mdDialogData, ae2HttpResponseMessagesService, ae2FeedbackService, ae2UserService, store, ae2DialogService, ae2SalesOrderService) {
        this.mdDialogRef = mdDialogRef;
        this.mdDialogData = mdDialogData;
        this.ae2HttpResponseMessagesService = ae2HttpResponseMessagesService;
        this.ae2FeedbackService = ae2FeedbackService;
        this.ae2UserService = ae2UserService;
        this.store = store;
        this.ae2DialogService = ae2DialogService;
        this.ae2SalesOrderService = ae2SalesOrderService;
        this.isLoading = false;
    }
    ChangeGiftComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.gift = this.mdDialogData.gift;
        this.form = this.createForm();
        this.form.get('sendAfter').valueChanges
            .pipe(debounceTime(100))
            .subscribe(function (value) {
            if (value.length === 16) {
                var dateCheck = value.substr(6, 4) + "-" + value.substr(3, 2) + "-" + value.substr(0, 2) + " " + value.substr(11, 5);
                var check = new Ae2DateTime(dateCheck).isAfter(Ae2DateTime.now());
                if (!check) {
                    _this.form.get('sendAfter').setValue(Ae2DateTime.now().format('DD/MM/YYYY HH:mm'));
                }
            }
        });
    };
    ChangeGiftComponent.prototype.createForm = function () {
        return new FormGroup({
            recipientEmail: new FormControl(this.gift.recipientEmail, Validators.required),
            sendAfter: new FormControl(this.gift.sendAfter ? new Ae2DateTime(this.gift.sendAfter).format('DD/MM/YYYY HH:mm') : ''),
            recipientMessage: new FormControl(this.gift.recipientMessage),
        });
    };
    ChangeGiftComponent.prototype.close = function () {
        this.mdDialogRef.close(new Ae2DialogResponse(false));
    };
    ChangeGiftComponent.prototype.submit = function () {
        var _this = this;
        var data = new Ae2RedeemGiftCardModel();
        data.recipientEmail = this.form.get('recipientEmail').value;
        var after = this.form.get('sendAfter').value;
        data.sendAfter = after ? after.substr(6, 4) + "-" + after.substr(3, 2) + "-" + after.substr(0, 2) + " " + after.substr(11, 5) : null;
        data.recipientMessage = this.form.get('recipientMessage').value;
        this.ae2SalesOrderService.pathGiftCards(this.gift.token, data).subscribe(function (res) {
            _this.ae2FeedbackService.success({
                message: 'Seu giftcard foi alterado com sucesso',
                format: Ae2FeedbackFormat.Notification
            });
            _this.mdDialogRef.close(new Ae2DialogResponse(true, __assign({}, _this.gift, res)));
        }, function (error) {
            _this.ae2HttpResponseMessagesService.createFeedback(error);
            _this.isLoading = false;
            _this.mdDialogRef.close(new Ae2DialogResponse(false));
        });
    };
    return ChangeGiftComponent;
}());
export { ChangeGiftComponent };
